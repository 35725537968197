export default [
    {
        path: '/serviceStaff',
        name: 'serviceStaff',
        component: () => import('@/views/personnelManagement/serviceStaff/index'),
        meta: {
            title: '服务人员',
        }
    },
    {
        path: '/commodity',
        name: 'commodity',
        component: () => import('@/views/personnelManagement/commodity/index'),
        meta: {
            title: '商品列表',
        }
    },
    {
        path: '/commodityAdd',
        name: 'commodityAdd',
        component: () => import('@/views/personnelManagement/commodity/commodityAdd'),
        meta: {
            title: '新增商品',
        }
    },
    {
        path: '/commodityEdit',
        name: 'commodityEdit',
        component: () => import('@/views/personnelManagement/commodity/commodityEdit'),
        meta: {
            title: '编辑商品',
        }
    },
    {
        path: '/appreciation',
        name: 'appreciation',
        component: () => import('@/views/personnelManagement/appreciation/index.vue'),
        meta: {
            title: '增购服务',
        }
    },
    {
        path: '/shopCouponCode',
        name: 'shopCouponCode',
        component: () => import('@/views/personnelManagement/shopCouponCode/index.vue'),
        meta: {
            title: '优惠码管理',
        }
    },
    {
        path: '/activityManage',
        name: 'activityManage',
        component: () => import('@/views/personnelManagement/activityManage/index.vue'),
        meta: {
            title: '活动管理',
        }
    },
]