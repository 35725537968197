export default [
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/orderManagement/orders/index.vue'),
        meta: {
            title: '订单列表',
        },
    },
    {
        path: '/serviceOrders',
        name: 'serviceOrders',
        component: () => import('@/views/orderManagement/serviceOrders/index.vue'),
        meta: {
            title: '服务单列表',
            keepAlive: true,
        },
    },
    {
        path: '/evaluation',
        name: 'evaluation',
        component: () => import('@/views/orderManagement/evaluation/index.vue'),
        meta: {
            title: '评价管理',
        },
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/orderManagement/orders/orderDetail.vue'),
        meta: {
            title: '订单详情',
        },
    },
    {
        path: '/serviceDetail',
        name: 'serviceDetail',
        component: () => import('@/views/orderManagement/serviceOrders/serviceDetail.vue'),
        meta: {
            title: '服务详情',
        },
    },
    {
        path: '/evaluationDetail',
        name: 'evaluationDetail',
        component: () => import('@/views/orderManagement/evaluation/detail.vue'),
        meta: {
            title: '评价详情',
        },
    },
    {
        path: '/userAppeal',
        name: 'userAppeal',
        component: () => import('@/views/orderManagement/userAppeal/index.vue'),
        meta: {
            title: '用户申诉'
        }
    },
    {
        path: '/userAppealDetail',
        name: 'userAppealDetail',
        component: () => import('@/views/orderManagement/userAppeal/detail.vue'),
        meta: {
            title: '用户申诉详情',
            keepAlive: true,
        }
    }

]